import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes } from '@hum/icm-app/src/state';
import { Button, Text, TextType } from '@hum/ui-library';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export type EmptyConnectorTabProps = {
  icon: string;
  title: string;
  description: string;
};

export const EmptyConnectorTab = ({
  title,
  description,
  icon,
}: EmptyConnectorTabProps) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <div className="flex flex-col items-center">
      <img className="h-auto max-w-xs m-4" src={icon} alt={title} />
      <Text className="max-w-md text-center" type={TextType.DISPLAY_S}>
        {title}
      </Text>
      <Text className="max-w-md text-center" type={TextType.BODY_S}>
        {description}
      </Text>
      <Button
        className="m-6"
        onClick={() => history.push(`${location.pathname}/connect`)}
      >
        Connect
      </Button>
      <div className="flex items-center gap-2 p-6 mb-8 border-2 rounded-full border-borderColor">
        <ExclamationCircleIcon className="w-6 h-6" />
        <Text type={TextType.UI_ELEMENT}>Prefer not to connect?</Text>
        <span
          className="ui-link"
          onClick={() =>
            history.push(Routes.COMPANY_ACTIVATION_DOCUMENT_DATAROOM)
          }
        >
          Import financial files
        </span>
      </div>
    </div>
  );
};
